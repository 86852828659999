import React from "react";
import styled from "styled-components";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../../components/Button";

const Contents = styled.div`
  margin: 140px 30px 30px 30px;

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }
`;

const Label = styled.label`
  align-self: center;
  text-align: center;
  font-size: 16px;
  width: 210px;
`;

const MobileRecordPage = ({
  setBlob,
}: {
  setBlob: (blob: any) => any;
}): JSX.Element => (
  <Contents>
    <Button className="button-upload">
      <Label htmlFor="file-upload">
        <FontAwesomeIcon icon={faVideo} /> Use camera
      </Label>
      <input
        id="file-upload"
        type="file"
        accept="video/*"
        capture="user"
        onChange={(e) => {
          if (e.target.files) {
            const file = e.target.files[0];
            setBlob(file);
          }
        }}
      />
    </Button>
  </Contents>
);

export default MobileRecordPage;
