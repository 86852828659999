import React from "react";
import styled from "styled-components";

const red = "#eb5757";
const white = "white";
const borderColour = "rgba(235, 87, 87, 0.5)";

const Container = styled.button`
  height: 70px;
  width: 70px;
  position: relative;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  border-radius: 50%;
`;

const CentralCircle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  transform: translate(8%,8%);
  background: ${white};
  height: 60px;
  width: 60px;
`;

const CentralSquare = styled.div`
  position: absolute;
  left: 30.77%;
  right: 30.77%;
  top: 30.77%;
  bottom: 30.77%;
  background-color: ${red};
  border: 5px solid rgba(255, 0, 0, 0.2);
  border-radius: 5px;
`;

const BorderCircleRecording = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 6px solid ${borderColour};
  border-radius: 50%;
  transform: scale(1.3);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      // transform: scale(1);
      border: 10px solid ${borderColour};
    }

    25% {
      // transform: scale(1.2);
      border: 6px solid ${borderColour};
    }

    50% {
      // transform: scale(1.2);
      border: 10px solid ${borderColour};
    }

    75% {
      // transform: scale(1.2);
      border: 6px solid ${borderColour};
    }
  
    100% {
      // transform: scale(1);
      border: 10px solid ${borderColour};
    }
  }
`;

const BorderCircle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 3px solid ${white};
  border-radius: 50%;
`;

export const StartRecordingButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => any;
}): JSX.Element => (
  <Container onClick={onClick} className="button-start-recording">
    <CentralCircle />
    <BorderCircle />
  </Container>
);

export const StopRecordingButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => any;
}): JSX.Element => (
  <Container onClick={onClick} className="button-stop-recording">
    <CentralSquare />
    <BorderCircleRecording />
  </Container>
);
