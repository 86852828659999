import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import InviteModal from "./InviteModal";
import { DataContext } from "../state/DataContext";
import { TextProgressBar } from "./ProgressBar";

const Container = styled.div`
  position: fixed;
  right: 0;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 250px;
  padding-top: 5px;
`;

const StyledLink = styled.div`
  color: ${(props) => props.theme.colors.primary};
  margin: 30px 25px 30px 9px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    transition: all 100ms ease;
  }
`;

const Avatar: React.FC = () => {
  const { groupId } = useParams();
  const { group, uploading, setUploading, setSidebarOpen } = React.useContext(
    DataContext
  );

  // TODO: allow the user to do something about upload error...
  return groupId ? (
    <Container>
      <InviteModal code={group ? group.invite : "Loading"} />
      <StyledLink onClick={() => setSidebarOpen(true)} className="user-icon">
        <FontAwesomeIcon icon={faUserCircle} size="3x" />
      </StyledLink>
      {uploading !== 0 && (
        <TextProgressBar value={uploading} max={100} setValue={setUploading} />
      )}
    </Container>
  ) : (
    <div />
  );
};

export default Avatar;
