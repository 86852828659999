import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import { DatabaseError } from "../types";

const prodId = "async-hello";
const prodConfiguration = {
  apiKey: "AIzaSyBFPl2Pt5lLSuzlj3zPIz-ljTJMWfwObSg",
  authDomain: `${prodId}.firebaseapp.com`,
  databaseURL: `https://${prodId}.firebaseio.com`,
  storageBucket: `${prodId}.appspot.com`,
  projectId: prodId,
  messagingSenderId: "52112770894",
  appId: "1:52112770894:web:94b470b4639f9b8c17401f",
  measurementId: "G-55X1ZEFV7M",
};

const stagingId = "async-hello-staging";
const stagingConfiguration = {
  apiKey: "AIzaSyDh5E2cRViF4Aca1qX8CKq0dA8XFercZCo",
  authDomain: `${stagingId}.firebaseapp.com`,
  databaseURL: `https://${stagingId}.firebaseio.com`,
  storageBucket: `${stagingId}.appspot.com`,
  projectId: stagingId,
  messagingSenderId: "64052531773",
  appId: "1:64052531773:web:22470d603c119aa7531054",
  measurementId: "G-BJZ2M2K6CC",
};

export const projectId =
  process.env.REACT_APP_ENV === "production" ? prodId : stagingId;

const configuration =
  process.env.REACT_APP_ENV === "production"
    ? prodConfiguration
    : stagingConfiguration;

firebase.initializeApp(configuration);
firebase.analytics();
export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();

export const usersCollection = firestore.collection("users");
export const groupsCollection = firestore.collection("groups");
export const videosCollection = firestore.collection("videos");
export const invitesCollection = firestore.collection("invites");

export const getUser = (userId: string): any => usersCollection.doc(userId);
export const getGroup = (groupId: string): any => groupsCollection.doc(groupId);
export const getVideo = (videoId: string): any => videosCollection.doc(videoId);

// TODO make these more granular and informative
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseError = (e: any): DatabaseError =>
  e.message && e.message.includes("permissions")
    ? DatabaseError.InsufficientPermissions
    : DatabaseError.UnknownError;
