import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBackspace } from "@fortawesome/free-solid-svg-icons";

import FullscreenVideo from "../../components/Video";
import Controllers from "../../components/Controllers";
import ConfirmationModal from "../../components/ConfirmationModal";

export const maxDescriptionLength = 240;

const DescriptionBox = styled.textarea`
  height: 120px;
  width: 300px;
  border-radius: 10px;
  background: rgba(242, 242, 242, 0.9);
  border: none;
  resize: none;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;

  flex-flow: wrap;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)<{ width?: string }>`
  cursor: pointer;
  margin: 5px;
  transition: color 0.5s ease;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }

  ${(props) => props.width && `min-width: ${props.width};`}
`;

export const SaveRecordingButton = ({
  onClick,
}: {
  onClick?: (e: React.MouseEvent) => any;
}): JSX.Element => (
  <Icon
    icon={faCheckCircle}
    size="4x"
    color={"orange"}
    onClick={onClick}
    className="button-save-recording"
  />
);

export const DiscardRecordingButton = (): JSX.Element => (
  <Icon
    icon={faBackspace}
    size="2x"
    color="white"
    width={"60px"}
    className="button-discard-recording"
  />
);

const SaveVideoPage = ({
  blob,
  uploadVideo,
  discardVideo,
}: {
  blob: Blob;
  uploadVideo: (desc: string) => any;
  discardVideo: () => any;
}): JSX.Element => {
  const [description, setDescription] = React.useState("");
  const [blobUrl, setBlobUrl] = React.useState("");

  React.useEffect(() => {
    if (blob) {
      const newUrl = URL.createObjectURL(blob);
      setBlobUrl(newUrl);
    }
  }, [blob]);

  return (
    <>
      <FullscreenVideo src={blobUrl} />
      <Controllers
        left={
          <DescriptionBox
            placeholder="Write a caption to go with your video here (max 240 characters)"
            maxLength={maxDescriptionLength}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input-description"
          />
        }
        centre={
          <Flex>
            {description.length > 0 ? (
              <SaveRecordingButton onClick={() => uploadVideo(description)} />
            ) : (
              <ConfirmationModal
                text="Save without a description?"
                action="Save"
                onClick={() => uploadVideo(description)}
              >
                <SaveRecordingButton />
              </ConfirmationModal>
            )}
            <ConfirmationModal text="Discard video?" onClick={discardVideo}>
              <DiscardRecordingButton />
            </ConfirmationModal>
          </Flex>
        }
      />
    </>
  );
};

export default SaveVideoPage;
