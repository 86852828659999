import React from "react";
import styled from "styled-components";
import Page from "../components/Page";
import Footer from "../components/Footer";

const Main = styled.main`
  margin: 120px 40px;
  padding-bottom: 70px;
  width: min(calc(750px, 100%));
`;

const ContactPage: React.FC = (props) => {
  return (
    <Page inverse>
      <Main>{props.children}</Main>
      <Footer />
    </Page>
  );
};

export default ContactPage;
