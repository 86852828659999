import React from "react";
import Page from "../components/Page";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Contents = styled.div`
  margin: 80px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    transition: all 100ms ease;
  }
`;

const NotFoundPage: React.FC = () => (
  <Page>
    <Contents className="not-found-page">
      <div>404 not found</div>
      <StyledLink to="/" className="link-home">
        Go back to landing page
      </StyledLink>
    </Contents>
  </Page>
);

export default NotFoundPage;
