import React from "react";
import TextPage from "../components/TextPage";

const TermsPage: React.FC = () => (
  <TextPage>
    <h1>Terms &amp; Conditions</h1>
    <p>Last Updated: 1 July 2020</p>
    <p>
      <strong>
        <em>
          By signing up for and installing Async Hello, you agree to the
          following terms of service, as well as the privacy policy.
        </em>
      </strong>
    </p>
    <p>
      You will not use the service in any unlawful way. This includes but is not
      limited to attempts to hack, social engineer or in other ways get hold of
      user data.
    </p>
    <p>
      You will not provide a recording of a person that is not you, thereby
      impersonating another person's likeness.
    </p>
  </TextPage>
);

export default TermsPage;
