import React from "react";
import Button from "./Button";
import StyledModal from "./Modal";
import styled from "styled-components";

const Contents = styled.div`
  text-align: center;
`;

const ConfirmationModal = ({
  text,
  action,
  onClick,
  children,
}: {
  text: string;
  action?: string;
  onClick: any;
  children: any;
}): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div>
      <div onClick={() => setIsOpen(true)} className="confirm-trigger">
        {children}
      </div>
      <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Contents className="confirm-modal">
          <h4>{text}</h4>
          <Button
            inverted
            onClick={() => setIsOpen(false)}
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onClick();
              setIsOpen(false);
            }}
            className="confirm-button"
          >
            {action ? action : "Yes"}
          </Button>
        </Contents>
      </StyledModal>
    </div>
  );
};

export default ConfirmationModal;
