import React from "react";
import TextPage from "../components/TextPage";

export const helpEmail = "help@asynchello.com";
export const helloEmail = "hello@asynchello.com";

const ContactPage: React.FC = () => (
  <TextPage>
    <h1>Contact Us</h1>
    <p>
      If you have feedback or suggestions for Async Hello, please email us on{" "}
      <a href={`mailto:${helloEmail}`} className="mail-hello">
        {helloEmail}
      </a>
      . Video messages welcome!
    </p>
    <h2>Support</h2>
    <p>
      If you have any technical issues with with Async Hello, please email us on{" "}
      <a href={`mailto:${helpEmail}`} className="mail-help">
        {helpEmail}
      </a>
      . Feel free to send us a Loom or other video recording of what's up, as
      this makes it easier to debug!
    </p>
  </TextPage>
);

export default ContactPage;
