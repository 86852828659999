import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const CancelIcon = styled.div`
  color: ${(props) => props.theme.colors.muted};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  transition: color ease-in-out 150ms;
  text-align: right;
  cursor: pointer;
`;

const customStyles = {
  overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.5)" },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    maxWidth: "500px",
    padding: "20px",
  },
};

const StyledModal = ({
  isOpen,
  setIsOpen,
  children,
}: {
  isOpen: boolean;
  setIsOpen?: (open: boolean) => void;
  children: React.ReactNode;
}): JSX.Element => {
  const closeModal = setIsOpen ? () => setIsOpen(false) : undefined;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Modal"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
    >
      {setIsOpen && (
        <CancelIcon className="close-modal">
          <FontAwesomeIcon icon={faTimes} onClick={closeModal} />
        </CancelIcon>
      )}
      {children}
    </Modal>
  );
};

export default StyledModal;
