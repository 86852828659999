import React from "react";
import { signInWithGoogle } from "../auth";
import Page from "../components/Page";
import styled from "styled-components";
import { DataContext } from "../state/DataContext";
import LoadingSpinner from "../components/LoadingSpinner";
import { useParams, useHistory } from "react-router-dom";
import { Group } from "../types";
import { getInviteDetails, fulfillInvite } from "../db";
import GoogleButton from "../static/images/GoogleButton.png";
import Logo from "../components/Logo";
import { detectMobile, getSlackRedirectUri, getSlackState } from "../utils";

const Contents = styled.div`
  margin: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  text-align: center;
  z-index: 10;
`;

const buttonWidth = "190";
const GButton = styled.img`
  width: ${buttonWidth}px;
  height: 45px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const SlackButton = ({ domain }: { domain?: string }) => (
  <a
    href={`https://slack.com/oauth/v2/authorize?user_scope=identity.basic&identity.team&client_id=${
      process.env.REACT_APP_SLACK_CLIENT_ID
    }&redirect_uri=${encodeURI(
      getSlackRedirectUri()
    )}&state=${getSlackState()}${domain ? `&team=${domain}` : ""}`}
    className="button-slack"
  >
    <img
      width={buttonWidth}
      alt="Add to Slack"
      src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
      srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
    />
  </a>
);

const SignUpPage: React.FC = () => {
  const { loadingUser, userId } = React.useContext(DataContext);
  const { code } = useParams();
  const [group, setGroup] = React.useState<Partial<Group>>({});
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const isMobile = detectMobile();

  React.useEffect(() => {
    if (code) {
      const fetchInviteDetails = async () => {
        setLoading(true);
        const info = await getInviteDetails(code);
        if (info.success) {
          setGroup(info.data);
          setLoading(false);
        }
      };
      fetchInviteDetails();
    }
  }, [code]);

  React.useEffect(() => {
    if (code && userId && group.id) {
      const joinAndRedirect = async () => {
        setLoading(true);
        await fulfillInvite(userId, code);
        history.push(`/${group.id}/introductions`);
      };
      joinAndRedirect();
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, userId, group.id]);

  return (
    <Page inverse hideLogo={!isMobile}>
      {isMobile ? null : <Logo width="800" height="800" />}
      <Contents>
        {loadingUser || loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {group.name ? (
              <h1>Join the {group.name} space</h1>
            ) : (
              <h1>Sign Up</h1>
            )}
            {!group.domain && (
              <GButton
                src={GoogleButton}
                onClick={() => signInWithGoogle()}
                className="button-google"
              />
            )}
            <SlackButton domain={group.domain} />
          </>
        )}
      </Contents>
    </Page>
  );
};

export default SignUpPage;
