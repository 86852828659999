import React from "react";
import styled from "styled-components";
import {
  faAngleDown,
  faAngleUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { DataContext } from "../state/DataContext";
import { List } from "./List";

const Container = styled.div`
  cursor: pointer;
`;

const GroupName = styled.div`
  position: fixed;
  top: 50px;
  left: 100px;
  text-decoration: none;
  z-index: 3;
  font-weight: 700;
  text-transform: capitalize;
`;

const DropdownMenu = styled(List)`
  position: absolute;
  top: 65px;
  left: 90px;
  text-transform: capitalize;
`;

const DropdownItem = styled(Link)`
  text-decoration: none;
  padding: 5px 10px;
  font-size: 14px;
`;

const GroupMenu: React.FC = () => {
  const { groupId, group, groupNames, setCategory } = React.useContext(
    DataContext
  );
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const otherGroupIds = Object.keys(groupNames).sort();

  return group ? (
    <Container>
      <GroupName
        className="overlayText"
        onClick={() => group && !group.domain && setIsMenuOpen(!isMenuOpen)}
      >
        {group && !group.domain && (
          <FontAwesomeIcon icon={isMenuOpen ? faAngleUp : faAngleDown} />
        )}{" "}
        {group.name}
      </GroupName>
      {isMenuOpen && (
        <DropdownMenu>
          {otherGroupIds.map((id) => (
            <DropdownItem
              to={`/${id}/introductions`}
              key={id}
              className="overlayText"
              onClick={() => setCategory("", "")}
            >
              {groupNames[id]}
              {groupId === id && " (current)"}
            </DropdownItem>
          ))}
          <DropdownItem to={`/spaces`} className="overlayText">
            <FontAwesomeIcon icon={faPlus} /> new space
          </DropdownItem>
        </DropdownMenu>
      )}
    </Container>
  ) : (
    <div />
  );
};

export default GroupMenu;
