import React from "react";
import styled from "styled-components";

const Container = styled.div<{ contentEditable: boolean }>`
  margin-bottom: 20px;
  overflow-wrap: break-word;
  border: ${(props) =>
    props.contentEditable ? `2px solid white` : `2px solid transparent`};
`;

const EditableText = ({
  editable,
  text,
  maxLength,
  onChange,
}: {
  editable: boolean;
  text: string;
  maxLength: number;
  onChange: (text: string | null) => any;
}): JSX.Element => {
  return (
    <Container
      className="editable-text"
      contentEditable={editable}
      suppressContentEditableWarning
      onKeyUp={(e) => {
        if (
          e.currentTarget.textContent &&
          e.currentTarget.textContent.length >= maxLength
        ) {
          e.preventDefault();
          e.currentTarget.textContent = e.currentTarget.textContent.substring(
            0,
            maxLength
          );
        } else {
          e.currentTarget.textContent &&
            e.currentTarget.textContent.length > 1 &&
            onChange(e.currentTarget.textContent);
        }
      }}
    >
      {text}
    </Container>
  );
};

export default EditableText;
