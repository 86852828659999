/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Page from "../../components/Page";
import { createVideo } from "../../db";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DataContext } from "../../state/DataContext";
import { detectMobile } from "../../utils";
import { ErrorMessage } from "../../components/ErrorMessage";
import WebcamRecordPage, { options } from "./WebcamRecordPage";
import SaveVideoPage from "./SaveVideoPage";
import MobileRecordPage from "./MobileRecordPage";

const RecordPage: React.FC = (props: any) => {
  const { userId, setUploading, group } = React.useContext(DataContext);

  const history = useHistory();
  const { groupId, categoryId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const isWelcomeVideo = query.get("welcome") === "true";

  const [blob, setBlob] = React.useState<Blob | null>(null);
  const [duration, setDuration] = React.useState(0);
  const [error, setError] = React.useState("");

  const isMobile = detectMobile();

  React.useEffect(() => {
    try {
      if (!isMobile && !MediaRecorder.isTypeSupported(options.mimeType)) {
        setError(
          "Sorry, this browser does not support recording " + options.mimeType
        );
      }
    } catch (e) {
      setError("Sorry, this browser does not support MediaRecorder.");
    }
  }, []);

  const uploadVideo = async (description: string) => {
    if (userId && blob) {
      createVideo(
        groupId,
        userId,
        categoryId,
        description,
        blob,
        isWelcomeVideo,
        setUploading,
        group?.categories[categoryId].webhook?.url,
        duration
      );
      history.push(`/${groupId}/${categoryId}`);
    } else {
      // eslint-disable-next-line no-console
      console.error("Error saving video");
    }
  };

  return (
    <Page>
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : blob ? (
        <SaveVideoPage
          blob={blob}
          uploadVideo={uploadVideo}
          discardVideo={() => setBlob(null)}
        />
      ) : isMobile ? (
        <MobileRecordPage setBlob={setBlob} />
      ) : (
        <WebcamRecordPage setBlob={setBlob} setDuration={setDuration} />
      )}
    </Page>
  );
};

export default RecordPage;
