import * as firebase from "firebase/app";

import "firebase/auth";

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = (): Promise<any> =>
  firebase.auth().signInWithRedirect(googleProvider);

export const signOut = (): Promise<void> => firebase.auth().signOut();

export const subscribe = (callback: (user: any) => any): (() => void) =>
  firebase.auth().onAuthStateChanged(callback);

export const signInWithToken = (token: string): Promise<any> =>
  firebase.auth().signInWithCustomToken(token);
