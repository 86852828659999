import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/NotFoundPage";
import { DataProvider } from "./state/DataContext";
import SignUpPage from "./pages/SignUpPage";
import RecordPage from "./pages/RecordPage";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import GroupPage from "./pages/GroupPage";
import CreateGroupPage from "./pages/CreateGroupPage";
import JoinPage from "./pages/JoinPage";
import SlackAuthRedirect from "./pages/SlackAuthRedirect";
import ContactPage from "./pages/ContactPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <DataProvider>
          <Switch>
            <Route path="/" exact>
              <LandingPage />
            </Route>
            <Route path="/slack-auth-redirect" exact>
              <SlackAuthRedirect />
            </Route>
            <Route path="/contact" exact>
              <ContactPage />
            </Route>
            <Route path="/privacy" exact>
              <PrivacyPolicyPage />
            </Route>
            <Route path="/terms" exact>
              <TermsPage />
            </Route>
            <Route path="/signup" exact>
              <SignUpPage />
            </Route>
            <Route path="/spaces" exact>
              <CreateGroupPage />
            </Route>
            <Route path="/join/:code" exact>
              <JoinPage />
            </Route>
            <Route path="/signup/:code" exact>
              <SignUpPage />
            </Route>
            <Route path="/:groupId/:categoryId/record">
              <RecordPage />
            </Route>
            <Route path="/:groupId/:categoryId/">
              <GroupPage />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </DataProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
