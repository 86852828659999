import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage } from "./ErrorMessage";

const StyledVideo = styled.video`
  z-index: 0;
  position: relative;
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  object-position: 50% 50%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: hidden;
`;

const Overlay = styled.div`
  background: linear-gradient(
    rgba(159, 159, 159, 0),
    rgba(57, 57, 57, 0.1),
    rgba(0, 0, 0, 0.5)
  );
  flex: 1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
`;

const Icon = styled.div`
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: 45%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

interface Props {
  src: string;
  isVisible?: boolean;
  logWatch?: () => any;
}

const quotaError =
  "Videos aren't loading right now because our server are overloaded. Please let Angie or Andreja know you're seeing this error, and check back in tomorrow morning when our quota resets!";
const formatError =
  "Sorry, this app is not optimised for this browser. Please give it a try with Chrome on your laptop.";

const Video = ({ src, isVisible = true, logWatch }: Props): JSX.Element => {
  const [playing, setPlaying] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [error, setError] = React.useState("");

  // Attempting to prevent re-downloading the video on replays
  const [blob, setBlob] = React.useState<Blob | null>(null);
  const [url, setUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const requestVideo = async () => {
      const response = await fetch(src);
      const newBlob: Blob = await response.blob();
      setBlob(newBlob);
    };

    if (!src) {
      // TODO verify the assumption that this is a quota error
      setError(quotaError);
    } else if (isVisible && !url) {
      setError("");
      requestVideo();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, isVisible]);

  React.useEffect(() => {
    if (blob) {
      const newUrl = URL.createObjectURL(blob);
      setUrl(newUrl);
    }
  }, [blob]);

  React.useEffect(() => {
    const autoplay = async () => {
      if (videoRef && videoRef.current) {
        if (isVisible) {
          // Won't always work on Chrome, because user needs to interact with the document first
          try {
            await videoRef.current.play();
            logWatch && logWatch();
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log("Can't autoplay");
          }
        } else {
          videoRef.current.pause();
        }
      }
    };

    autoplay();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleClick = async () => {
    if (videoRef && videoRef.current) {
      if (playing) {
        videoRef.current.pause();
      } else {
        try {
          await videoRef.current.play();
          logWatch && logWatch();
        } catch (e) {
          setError(formatError);
        }
      }
    }
  };
  // Firefox does not like changing the src attribute
  return url && !error ? (
    <Overlay onClick={handleClick} className="overlay">
      <StyledVideo
        className="video"
        src={url}
        ref={videoRef}
        autoPlay={isVisible}
        playsInline // helps with Chrome autoplay?
        //muted // if we added muted here, Chrome autoplay would work... on mute
        onClick={handleClick}
        onEnded={() => setPlaying(false)}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onError={(e) => {
          // TODO: verify why this error is happenninng
          // eslint-disable-next-line no-console
          console.log("Error playing video", e);
          if (navigator.userAgent.indexOf("Chrome") === -1) {
            //setError(formatError);
          } else {
            setError(quotaError);
          }
        }}
      ></StyledVideo>
      {!playing && (
        <Icon onClick={handleClick} className="play-button">
          <FontAwesomeIcon
            icon={faPlay}
            color="rgba(255,255,255,0.3)"
            size="3x"
          />
        </Icon>
      )}
    </Overlay>
  ) : (
    <Container>{error && <ErrorMessage>{error}</ErrorMessage>}</Container>
  );
};

export default Video;
