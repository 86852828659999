import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Avatar from "./Avatar";
import { DataContext } from "../state/DataContext";
import GroupMenu from "./GroupMenu";
import Sidebar from "./Sidebar";

export const StyledPage = styled.div<{ inverse?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${window.innerHeight}px;
  font-size: 16px;
  background: ${(props) =>
    props.inverse ? props.theme.colors.text : props.theme.colors.background};
  color: ${(props) =>
    props.inverse ? props.theme.colors.background : props.theme.colors.text};

  .overlayText {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 1;
    color: ${(props) => props.theme.colors.text};
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
  }
`;

interface Props {
  inverse?: boolean;
  children?: React.ReactNode;
  hideLogo?: boolean;
}

const Page = (props: Props): JSX.Element => {
  const { userId, group } = React.useContext(DataContext);
  return (
    <StyledPage inverse={props.inverse}>
      {props.hideLogo ? null : <Logo />}
      {group && <GroupMenu />}
      {userId && <Avatar />}
      {props.children}
      <Sidebar />
    </StyledPage>
  );
};

export default Page;
