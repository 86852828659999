import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LogoWhite from "../static/images/LogoWhite";

const StyledFooter = styled.footer`
  padding: 70px 40px 70px;
  display: block;
  color: white;
  background: linear-gradient(
    ${(props) => props.theme.colors.primary},
    ${(props) => props.theme.colors.secondary}
  );
  width: 100%;
  font-size: 16px;
  font-weight: 500;

  a {
    text-decoration: none;
    color: white;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Col = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

const Footer = (): JSX.Element => (
  <StyledFooter>
    <Row>
      <Col className="logo-footer">
        <LogoWhite />
      </Col>
    </Row>
    <Row>
      <Col className="logo-about">
        <h4>Async Hello</h4>
        <em>Personal connection, asynchronously</em>
        <h4>Created by</h4>
        Andreja Kogovsek &amp; Angela Branaes
      </Col>
      <Col className="logo-us">
        <h4>Us</h4>
        <Link to={`/contact`} className="link-contact">
          Contact us
        </Link>
      </Col>
      <Col>
        <h4>Legal</h4>
        <Link to={`/privacy`} className="link-privacy">
          Privacy policy
        </Link>
        <Link to={`/terms`} className="link-terms">
          Terms &amp; conditions
        </Link>
      </Col>
    </Row>
  </StyledFooter>
);

export default Footer;
