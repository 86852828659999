import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { getInviteDetails } from "../db";
import { Group } from "../types";

import { DataContext } from "../state/DataContext";
import Page from "../components/Page";
import LoadingSpinner from "../components/LoadingSpinner";
import NotSignedIn from "../components/NotSignedIn";

const LandingPage: React.FC = () => {
  const history = useHistory();
  const { loadingUser, user, setCategory } = React.useContext(DataContext);

  const { code } = useParams();
  const [group, setGroup] = React.useState<Partial<Group>>({});

  React.useEffect(() => {
    if (code) {
      const fetchInviteDetails = async () => {
        const info = await getInviteDetails(code);
        if (info.success) {
          setGroup(info.data);
        }
      };
      fetchInviteDetails();
    }
  }, [code]);

  // Make sure any group data has been cleared
  React.useEffect(() => {
    setCategory("", "");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to last visited group page for logged in users
  React.useEffect(() => {
    if (user && user.lastVisitedGroup) {
      history.push(`/${user.lastVisitedGroup}/introductions`);
    } else if (user) {
      history.push(`/spaces`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Page>
      {loadingUser ? (
        <LoadingSpinner />
      ) : (
        <NotSignedIn
          title={`Introduce yourself async to the ${group.name} group`}
          blurb={`Record your video introduction in your own time, instead of
          sitting through a scheduled Zoom meeting to learn who everyone in
          the ${group.name} space is.`}
          cta={`JOIN THE "${group.name}" SPACE`}
        />
      )}
    </Page>
  );
};

export default LandingPage;
