import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 180px;
`;

const ProgressBar = styled.progress<{ speed?: string; size?: string }>`
  z-index: 2;
  transition: all ${(props) => props.speed || "1s"} ease;
  border-radius: 20px;
  border: none;
  height: ${(props) => props.size || "20px"};
  background-color: ${(props) => props.theme.colors.text};
  width: 100%;

  ::-webkit-progress-bar {
    background-color: ${(props) => props.theme.colors.text};
    border-radius: 20px;
    border: 0;
    height: ${(props) => props.size || "20px"};
    transition: all ${(props) => props.speed || "1s"} ease;
  }

  ::-webkit-progress-value {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 20px;
    border: 0;
    height: ${(props) => props.size || "20px"};
    transition: all ${(props) => props.speed || "1s"} ease;
  }

  ::-moz-progress-bar {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 20px;
    border: 0;
    height: ${(props) => props.size || "20px"};
    transition: all ${(props) => props.speed || "1s"} ease;
  }
`;

export const TopProgressBar = styled(ProgressBar)`
  position: absolute;
  top: 90px;
  width: 80%;
  transition: all 1s ease;
`;

const Text = styled.div`
  position: absolute;
  top: 8px;
  z-index: 3;
  margin-left: 15px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.background};
`;

export const TextProgressBar = ({
  value,
  max,
  setValue,
  speed,
}: {
  value: number;
  max: number;
  setValue: (num: number) => void;
  speed?: string;
}): JSX.Element => {
  React.useEffect(() => {
    if (value === 100) {
      setTimeout(() => setValue(0), 2000);
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container className="progress">
      <ProgressBar
        value={value}
        max={max}
        speed={speed || "0.5s"}
        size={"30px"}
        className="progress-bar"
      />
      <Text className="progress-text">
        {value === 100
          ? "Upload complete!"
          : value === -1
          ? "Error uploading video."
          : "Uploading..."}
      </Text>
    </Container>
  );
};

export default ProgressBar;
