import React from "react";
import styled from "styled-components";
import { DataContext } from "../state/DataContext";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { signOut } from "../auth";
import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
import { Video } from "../types";
import { detectMobile } from "../utils";
import FullscreenVideo from "./Video";
import { faVideo, faTrashAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";
import { Link } from "react-router-dom";
import { deleteVideo, setBlurb, setUsername } from "../db";
import EditableText from "./EditableText";
import { maxDescriptionLength } from "../pages/RecordPage/SaveVideoPage";

const defaultWidth = 350;

const Container = styled.div<{ x: number; width: number; opaque?: boolean }>`
  position: fixed;
  right: 0;
  width: ${(props) => props.width}px;
  height: ${window.innerHeight}px;
  background-color: ${(props) => props.theme.colors.background};
  transform: translatex(${(props) => props.x}px);
  transition: all 1s ease;
  z-index: 7;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.opaque ? 1 : 0.95)};
`;

const TopButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  z-index: 4;
`;

const Name = styled.div`
  font-weight: 700;
  margin: 10px 0;
  font-size: 38px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 15px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  transition: all 100ms ease;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Div = styled.div`
  height: ${window.innerHeight}px;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  z-index: 5;
  /* Background blur */
  backdrop-filter: blur(50px);
  border-radius: 50px 50px 0px 0px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 30px;
`;

const NoVideo = styled.div`
  position: absolute;
  top: 50%;
  padding: 20px;
  font-weight: 500;
`;

const ButtonBar = ({
  introVideo,
  setSidebarOpen,
  isEditing,
  toggleEditing
}: {
  introVideo: Video | null;
  setSidebarOpen: any;
  toggleEditing: any;
  isEditing: boolean;
}) => {
  const { groupId } = React.useContext(DataContext);
  const history = useHistory();
  return (
    <Buttons>
      {introVideo ? (
        <ConfirmationModal
          onClick={() => {
            setSidebarOpen(false);
            history.push(`/${groupId}/introductions/record`);
          }}
          text="Re-record intro video?"
        >
          <Button round inverted>
            <FontAwesomeIcon icon={faVideo} />
          </Button>
        </ConfirmationModal>
      ) : (
        <Link
          onClick={() => setSidebarOpen(false)}
          to={`/${groupId}/introductions/record`}
        >
          <Button round inverted>
            <FontAwesomeIcon icon={faVideo} />
          </Button>
        </Link>
      )}  
      <Button round inverted onClick={() => toggleEditing(!isEditing)}>
        <FontAwesomeIcon icon={isEditing ? faSave : faPen} />
      </Button>  
      {introVideo && (
        <ConfirmationModal
          text="Permanently delete video?"
          action={"DELETE"}
          onClick={() => {
            introVideo && deleteVideo(introVideo.id);
          }}
        >
          <Button round inverted danger>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </ConfirmationModal>
      )}
    </Buttons>
  );
};

const Sidebar = (): JSX.Element => {
  const {
    userId,
    groupId,
    user,
    group,
    videos,
    isSidebarOpen,
    setSidebarOpen,
    updateVideo,
  } = React.useContext(DataContext);
  const history = useHistory();

  const introId: string | null =
    user && groupId && user.groups[groupId] ? user.groups[groupId].intro : null;
  const introVideo: Video | null =
    introId && videos && Object.keys(videos).length > 0
      ? videos[introId]
      : null;

  const isMobile = detectMobile();
  const width = isMobile ? window.innerWidth : defaultWidth;
  const [isEditing, setIsEditing] = React.useState(false);
  const [blurb, updateBlurb] = React.useState(introVideo && (introVideo.blurb)); 
  const [username, updateUsername] = React.useState(userId && group && (group.users[userId]));

  const toggleEditing = () => {
    if (isEditing) {
      userId && group && username && setUsername(userId, group.id, username)
      blurb && saveBlurb(blurb)
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  const saveBlurb = (text: string) => {
    if (introVideo) {
      setBlurb(introVideo.id, text);
      updateVideo({ ...introVideo, blurb: text });
    }
  }

  return (
    <Container
      x={isSidebarOpen ? 0 : width}
      width={width}
      opaque={introVideo !== null}
    >
      <TopButtons>
        <Icon icon={faTimes} size="lg" onClick={() => setSidebarOpen(false)} />
        {userId && (
          <Button
            inverted
            onClick={async () => {
              await signOut();
              history.push("/");
              setSidebarOpen(false);
            }}
          >
            SIGN OUT
          </Button>
        )}
      </TopButtons>
      {introVideo ? (
        <Div>
          <FullscreenVideo
            src={introVideo.url || ""}
            isVisible={isSidebarOpen}
          />
        </Div>
      ) : (
        <NoVideo>
          Click the video button to record your welcome video. It will then show
          up here.
        </NoVideo>
      )}
      <Overlay>
        {userId && group && (
          <Name>
            <EditableText
              editable={!isMobile && isEditing}
              text={group.users[userId]}
              maxLength={30}
              onChange={updateUsername}
            />
          </Name>
        )}
        {introVideo && (
          <EditableText
            editable={!isMobile && isEditing}
            text={introVideo.blurb}
            maxLength={maxDescriptionLength}
            onChange={(text: string | null) => updateBlurb(text)}
          />
        )}
        <ButtonBar
          introVideo={introVideo}
          setSidebarOpen={setSidebarOpen}
          toggleEditing={toggleEditing}
          isEditing={isEditing}
        />
      </Overlay>
    </Container>
  );
};

export default Sidebar;
