import React from "react";
import TextPage from "../components/TextPage";
import { helpEmail } from "./ContactPage";

const PrivacyPolicyPage: React.FC = () => (
  <TextPage>
    <h1>Privacy Policy</h1>
    <p>Last Updated: 1 July 2020</p>
    <p>
      <strong>
        <em>
          Thank you for using Async Hello. This policy explains how we handle
          your personal data and privacy.
        </em>
      </strong>
    </p>
    <h2>Information collection and use</h2>
    <p>
      We collect your name when you sign in with Google or Slack. This is
      displayed to other users of your space. You are free to change your
      display name from your profile page.
    </p>
    <p>
      When you install the Async Hello slack app we gain access to the member's
      list for your space, as well as the ability to post in channels you
      configure Async Hello to post to. We will also be able to see when new
      team members join, to encourage them to record an intro video. We will
      also know when you uninstall the app or revoke its access, so we can
      update your data on our side.
    </p>
    <h2>Video Privacy</h2>
    <p>
      Only members of your space are able to see the intro and welcome videos
      you record. The links to the videos are private.
    </p>
    <h2>3rd Parties</h2>
    <p>
      We do not pass your data onto any 3rd parties, except for standard cloud
      storage and analytics.
    </p>
    <h2>Revisions to this Privacy Policy</h2>
    <p>
      We reserve the right to revise this Privacy Policy from time to time. We
      will post changes on this page and indicate the “last modified” date at
      the top of this page. Please check back for any updates. You acknowledge
      and agree that it is your responsibility to review this Privacy Policy
      periodically, and that your continued use of our Services after any change
      in this Privacy Policy will constitute your acceptance of such change.
    </p>
    <h2>Your Acceptance of These Terms</h2>
    <p>
      By using our Services, you signify your acceptance of this Privacy Policy.
    </p>
    <h2>Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us on{" "}
      <a href={`mailto:${helpEmail}`} className="mail-help">
        {helpEmail}
      </a>
      .
    </p>
  </TextPage>
);

export default PrivacyPolicyPage;
