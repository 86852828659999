import React from "react";
import styled from "styled-components";

const StyledButton = styled.button<{
  width?: string;
  inverted?: boolean;
  round?: boolean;
  danger?: boolean;
}>`
  background-color: ${(props) =>
    props.inverted ? "transparent" : props.theme.colors.primary};
  border: ${(props) =>
    `2px solid ${
      props.danger ? props.theme.colors.danger : props.theme.colors.primary
    }`};
  color: ${(props) => {
    let color = props.theme.colors.background;
    if (props.danger) {
      color = props.theme.colors.danger;
    }
    if (props.inverted && !props.danger) {
      color = props.theme.colors.primary;
    }
    return color;
  }};
  border-radius: ${(props) => (props.round ? "50%" : "999px")};
  width: fit-content;
  padding: ${(props) => (props.round ? "1rem" : "1rem 2rem")};
  margin: 8px 2px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  ${(props) => props.width && `width: ${props.width};`}

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    transition: all 100ms ease;
    border: ${(props) => `2px solid ${props.theme.colors.secondary}`};
  }
`;

interface Props {
  onClick?: (e: React.MouseEvent) => any;
  children?: React.ReactNode;
  width?: string;
  inverted?: boolean;
  round?: boolean;
  danger?: boolean;
  className?: string;
}

const Button = (props: Props): JSX.Element => (
  <StyledButton
    width={props.width}
    onClick={props.onClick}
    inverted={props.inverted}
    round={props.round}
    danger={props.danger}
    className={props.className}
  >
    {props.children}
  </StyledButton>
);

export default Button;
