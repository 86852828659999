export interface Theme {
  colors: {
    text: string;
    background: string;
    primary: string;
    secondary: string;
    muted: string;
    error: string;
    danger: string;
  };
}

export const theme = {
  colors: {
    text: "white",
    background: "#080813",
    primary: "#FFB661",
    secondary: "#FF6F61",
    muted: "#BDBDBD",
    error: "#EB5757",
    danger: "#f54343",
  },
};
