import React from "react";
import Button from "../components/Button";
import { createGroup } from "../db";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { List } from "../components/List";
import { Link } from "react-router-dom";
import { DataContext } from "../state/DataContext";
import Page from "../components/Page";

const width = "250px";

const Contents = styled.div`
  margin-top: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`;

const Text = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  margin: 1rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  font-size: 16px;
  border: 1px solid ${(props) => props.theme.colors.muted};
  border-radius: 4px;
  width: ${width};
`;

const Error = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: 13px;
  height: 20px;
  margin-bottom: 1rem;
  margin-left: 1rem;
  align-self: flex-start;
`;

const SelectGroup = () => {
  const { groupNames } = React.useContext(DataContext);
  return (
    <List style={{ marginTop: "20px" }} className="group-list">
      {Object.keys(groupNames).length > 0 && <h2>Your other spaces</h2>}
      {Object.keys(groupNames).map((groupId) => (
        <Link
          key={groupId}
          to={`/${groupId}/introductions`}
          className="group-button"
        >
          <Button width={"200px"}>{groupNames[groupId]}</Button>
        </Link>
      ))}
    </List>
  );
};

const CreateGroupPage = (): JSX.Element => {
  const { userId, setCategory } = React.useContext(DataContext);
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState("");
  const history = useHistory();

  // Make sure any group data has been cleared
  React.useEffect(() => {
    setCategory("", "");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Contents>
        <Text>What would you like your space to be called?</Text>
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            if (error) {
              setError("");
            }
          }}
          className="group-name-input"
        />
        <Error>
          {error && (
            <div className="group-create-error">
              <FontAwesomeIcon icon={faTimesCircle} /> {error}
            </div>
          )}
        </Error>
        <Button
          onClick={async () => {
            if (userId && name.length > 0) {
              const result = await createGroup(name, userId);
              if (!result.success) {
                setError("The name is already taken.");
              } else {
                history.push(`/${result.data}/introductions`);
              }
            } else {
              setError("Please enter a name.");
            }
          }}
          width={width}
          className="next-button"
        >
          NEXT
        </Button>
        <SelectGroup />
      </Contents>
    </Page>
  );
};

export default CreateGroupPage;
