import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Spinner = styled.div`
  color: ${(props) => props.theme.colors.muted};
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  min-height: 100vh;
`;

const LoadingSpinner: React.FC = () => (
  <Container>
    <Spinner className="loading-spinner">
      <FontAwesomeIcon icon={faSpinner} size="3x" />
    </Spinner>
  </Container>
);

export default LoadingSpinner;
