import React from "react";
import { Link, useRouteMatch, useParams, useLocation } from "react-router-dom";
import Button from "../components/Button";
import Page from "../components/Page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import FullscreenVideo from "../components/Video";
import { DataContext } from "../state/DataContext";
import LoadingSpinner from "../components/LoadingSpinner";
import { Video } from "../types";
import Controllers from "../components/Controllers";
import InviteModal from "../components/InviteModal";
import { useInView } from "react-hook-inview";
import FlatList from "flatlist-react";
import { logWatch, logVisit } from "../db";
import * as _ from "lodash";

const Description = styled.div`
  text-align: left;
  overflow-wrap: break-word;
  h1 {
    margin: 10px 0;
  }
  div {
    margin-bottom: 10px;
  }
`;

const FullScreen = styled.div`
  height: ${window.innerHeight}px;
  scroll-snap-align: start;
  position: relative;
`;

const Div = styled.div`
  overflow: scroll;
  height: ${window.innerHeight}px;
  width: 100vw;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(${window.innerHeight}px);
  scroll-snap-type: y mandatory;
`;

const defaultWelcomeVideo: Video = {
  id: "defaultWelcomeVideo",
  userId: "The Async Hello Team",
  groupId: "",
  category: "welcome",
  path: "welcome.mov",
  created: new Date(),
  seenBy: [],
  seen: false,
  url:
    process.env.REACT_APP_ENV === "production"
      ? "https://firebasestorage.googleapis.com/v0/b/async-hello.appspot.com/o/welcome.mov?alt=media&token=b1621f1c-5195-4c8b-92e4-bc8ab3a5dcc9"
      : "https://firebasestorage.googleapis.com/v0/b/async-hello-staging.appspot.com/o/welcome.mov?alt=media&token=92a82007-316e-4400-962e-15d0a72b4ea8",
  blurb:
    "Hi! Welcome to Async Hello! An async video service. We were tired of being stuck in meetings that could have been small video updates, and decided to do something about that.",
};

const RecordButton: React.FC = () => {
  const { url } = useRouteMatch();
  return (
    <Link to={`${url}/record`} className="record-button">
      <Button>
        <FontAwesomeIcon icon={faVideo} /> RECORD INTRO VIDEO
      </Button>
    </Link>
  );
};

const RecordWelcomeButton: React.FC = () => {
  const { url } = useRouteMatch();
  return (
    <Link to={`${url}/record?welcome=true`} className="record-button-welcome">
      <Button>
        <FontAwesomeIcon icon={faVideo} /> RECORD WELCOME VIDEO
      </Button>
    </Link>
  );
};

const UserVideo = ({
  video,
  alreadyRecordedVideo,
  isWelcome,
}: {
  video: Video;
  alreadyRecordedVideo: boolean;
  isWelcome?: boolean;
}): JSX.Element => {
  const { userId, group, videos } = React.useContext(DataContext);
  const [ref, isVisible] = useInView({ threshold: 1 });
  const author = group
    ? isWelcome
      ? group.welcomeVideo
        ? group.name
        : defaultWelcomeVideo.userId
      : group.users[video.userId]
    : "";
  return (
    <FullScreen>
      <FullscreenVideo
        src={video.url || ""}
        isVisible={isVisible}
        logWatch={
          userId && !video.seen ? () => logWatch(userId, video.id) : undefined
        }
      />
      <Controllers
        left={
          <Description ref={ref} className="description">
            <h1 className="overlayText">{author}</h1>
            <div className="overlayText">{video.blurb}</div>
          </Description>
        }
        centre={
          // TODO: don't show on the last video?
          Object.keys(videos).length > 1 && (
            <FontAwesomeIcon
              icon={faChevronDown}
              size="2x"
              color="rgba(255,255,255,0.5)"
              className="down-button"
            />
          )
        }
        right={
          <div>
            {Object.keys(videos).length === 1 && group && (
              <InviteModal code={group.invite} />
            )}
            {!alreadyRecordedVideo &&
              (group && group.welcomeVideo ? (
                <RecordButton />
              ) : (
                <RecordWelcomeButton />
              ))}
          </div>
        }
      />
    </FullScreen>
  );
};

const GroupPage: React.FC = () => {
  const {
    userId,
    user,
    loadingGroup,
    loadingUser,
    group,
    videos,
    setCategory,
  } = React.useContext(DataContext);
  const { groupId, categoryId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const firstVideoId = query.get("firstVideoId");
  const [alreadyRecordedVideo, setAlreadyRecordedVideo] = React.useState(false);
  const [welcomeVideo, setWelcomeVideo] = React.useState(defaultWelcomeVideo);
  const [sortedVideos, setSortedVideos] = React.useState([] as Video[]);

  React.useEffect(() => {
    if (!loadingUser && !loadingGroup && videos) {
      const newAlreadyRecordedVideo = userId
        ? Object.values(videos)
            .map((video) => video.userId)
            .includes(userId)
        : false;
      setAlreadyRecordedVideo(newAlreadyRecordedVideo);
    }
  }, [loadingUser, loadingGroup, userId, videos]);

  React.useEffect(() => {
    if (!loadingUser && !loadingGroup && group) {
      const newWelcomeVideo = group.welcomeVideo || defaultWelcomeVideo;
      setWelcomeVideo(newWelcomeVideo);
      const newSortedVideos = _.sortBy(Object.values(videos), [
        "created",
      ]).reverse();
      let allVideos: Video[] =
        loadingUser || loadingGroup || alreadyRecordedVideo
          ? newSortedVideos
          : [newWelcomeVideo, ...newSortedVideos];
      if (firstVideoId) {
        const firstVideo = videos[firstVideoId];
        if (firstVideo) {
          allVideos = [
            firstVideo,
            ...allVideos.filter((video) => video.id !== firstVideoId),
          ];
        }
      }

      setSortedVideos(allVideos);
    }
  }, [
    loadingUser,
    loadingGroup,
    group,
    alreadyRecordedVideo,
    videos,
    firstVideoId,
  ]);

  React.useEffect(() => {
    setCategory(groupId, categoryId);
  }, [groupId, categoryId, setCategory]);

  React.useEffect(() => {
    if (userId && user?.lastVisitedGroup !== groupId) {
      logVisit(userId, groupId);
    }
  }, [groupId, userId, user]);

  return (
    <Page>
      {loadingUser || loadingGroup || !group ? (
        <LoadingSpinner />
      ) : (
        <Div style={{ scrollSnapType: "y mandatory" }}>
          <FlatList
            list={sortedVideos}
            renderItem={(video: Video) => (
              <UserVideo
                video={video}
                alreadyRecordedVideo={alreadyRecordedVideo}
                isWelcome={video.id === welcomeVideo.id}
                key={video.id}
              />
            )}
            renderOnScroll
          />
        </Div>
      )}
    </Page>
  );
};

export default GroupPage;
