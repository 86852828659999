/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { subscribe } from "../auth";
import { DBUser, State, Video, User, Group, Category, DBGroup } from "../types";
import { useHistory, useLocation } from "react-router-dom";
import { getUser, createUser, getGroupNames, loadVideo, getGroup } from "../db";

const initialState: State = {
  userId: null,
  displayName: null,
  user: null,
  groupId: null,
  groupNames: {},
  categoryId: null,
  group: null,
  videos: {},
  loadingUser: true,
  loadingGroup: true,
  uploading: 0,
  isSidebarOpen: false,
  setSidebarOpen: (value) => {},
  setUploading: (num) => {},
  setCategory: (groupId, categoryId) => {},
  updateVideo: (video) => {},
};

export const DataContext = React.createContext<State>(initialState);

export const DataProvider = ({ children }: { children: any }): any => {
  const [userId, setUserId] = React.useState<string | null>(null);
  const [displayName, setDisplayName] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [groupId, setGroupId] = React.useState<string | null>(null);
  const [categoryId, setCategoryId] = React.useState<string | null>(null);
  const [groupNames, setGroupNames] = React.useState<{
    [groupId: string]: string;
  }>({});
  const [user, setUser] = React.useState<User | null>(null);
  const [group, setGroup] = React.useState<Group | null>(null);
  const [videos, setVideos] = React.useState<{ [id: string]: Video }>({});
  const history = useHistory();
  const location = useLocation();
  const [loadingUser, setLoadingUser] = React.useState(true);
  const [loadingGroup, setLoadingGroup] = React.useState(true);
  const [uploading, setUploading] = React.useState(0);
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = subscribe((newUser: any) => {
      setDisplayName(newUser ? newUser.displayName : undefined);
      setEmail(newUser ? newUser.email : undefined);
      setUserId(newUser ? newUser.uid : null);
      // Redirect users who just signed up/in
      if (newUser && location.pathname === "/signup") {
        setLoadingUser(false);
        history.push("/");
      }
      if (!newUser) {
        setLoadingUser(false);
        // Prevent not-logged-in users from accessing most pages
        if (
          !(
            location.pathname === "/" ||
            location.pathname === "/signup" ||
            location.pathname === "/slack-auth-redirect" ||
            location.pathname.includes("/join/")
          )
        ) {
          history.push("/");
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // subscribe to firestore user object when userId changes
  React.useEffect(() => {
    if (userId === null) {
      setUser(null);
      setGroupNames({});
      setGroup(null);
      setVideos({});
      return;
    }
    setLoadingUser(true);

    const unsubscribe = getUser(userId).onSnapshot(async (snapshot: any) => {
      const dbUser = snapshot.data() as DBUser;
      if (!dbUser) {
        await createUser(userId, displayName, email);
      }
      if (dbUser && dbUser.groups) {
        const newGroupNames = await getGroupNames(Object.keys(dbUser.groups));
        if (newGroupNames.success) {
          setGroupNames(newGroupNames.data);
        }
      }
      setUser({
        ...dbUser,
        userId: snapshot.id,
        displayName: snapshot.displayName,
        email: snapshot.email,
      });
      setLoadingUser(false);
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  React.useEffect(() => {
    if (!groupId) {
      setGroup(null);
      setVideos({});
      setLoadingGroup(true);
      return;
    }
    setLoadingGroup(true);
    const category = categoryId || "introductions";

    const unsubscribe = getGroup(groupId).onSnapshot(async (snapshot: any) => {
      const groupData = snapshot.data() as DBGroup;
      const categories: { [key: string]: Category } = {};
      if (groupData) {
        // eslint-disable-next-line array-callback-return
        Object.keys(groupData.categories).map((category): void => {
          categories[category] = {
            name: category,
            videos: groupData.categories[category].videos,
            webhook: groupData.categories[category].webhook,
          } as Category;
        });
        const newGroup: Group = {
          id: groupId,
          name: groupData.name,
          owners: groupData.owners,
          users: groupData.users,
          categories,
          invite: groupData.invite,
          domain: groupData.domain,
        };
        if (groupData.welcomeId) {
          const welcomeVideo = await loadVideo(groupData.welcomeId);
          if (welcomeVideo.success) {
            newGroup.welcomeVideo = welcomeVideo.data;
          }
        }
        const newVideos: { [id: string]: Video } = {};
        const categoryInfo = newGroup.categories[category];
        if (categoryInfo && categoryInfo.videos.length > 0) {
          for (const id of categoryInfo.videos) {
            const res = await loadVideo(id);
            if (res.success && res.data.url) {
              newVideos[id] = {
                ...res.data,
                seen: userId ? res.data.seenBy.includes(userId) : false,
              };
            }
          }
          setVideos(newVideos);
        }
        setGroup({
          ...newGroup,
          id: groupId,
        });
      }
    });
    setLoadingGroup(false);

    return () => {
      unsubscribe();
    };
  }, [userId, groupId, categoryId]);

  const setCategory = (newGroupId: string, newCategoryId: string) => {
    if (groupId !== newGroupId || categoryId !== newCategoryId) {
      setVideos({});
      setGroupId(newGroupId);
      setCategoryId(newCategoryId);
    }
  };

  // Optimistic updates since we're not subscribed to each video object
  const updateVideo = (video: Video) => {
    const newVideos = { ...videos, [video.id]: video };
    setVideos(newVideos);
  };

  const data: State = {
    userId,
    displayName,
    user,
    groupId,
    groupNames,
    categoryId,
    group,
    videos,
    loadingUser,
    loadingGroup,
    uploading,
    isSidebarOpen,
    setSidebarOpen,
    setUploading,
    setCategory,
    updateVideo,
  };

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};
