import {
  DatabaseResult,
  DBGroup,
  DBUser,
  DatabaseError,
  DBInvite,
  Group,
  DBWebhook
} from "../types";
import {
  groupsCollection,
  firestore,
  usersCollection,
  invitesCollection,
  parseError,
  getUser,
} from "./init";

export * from "./init";
export * from "./video";

export const initializeGroupIfNew = async (
  groupId: string,
  name: string,
  domain: string,
  userId: string,
  username: string,
  webhook?: DBWebhook,
  botToken?: string
): Promise<void> => {
  const doc = await groupsCollection.doc(groupId).get();
  if (!doc.exists) {
    const res = await createGroup(name, userId, groupId, domain, webhook, botToken);
    if (!res.success && res.error == DatabaseError.AlreadyExists) {
      // If res success == false here we KNOW that the group didn't exist, and if it's a collision it's a name collision
      console.log('Error when creating group. Name already exists. Adding "slack" to name')
      // Automatically rename group to have slack in the name
      await createGroup(`${name} (Slack group)`, userId, groupId, domain, webhook, botToken);
    }
    setUsername(userId, groupId, username);
  }
};

export const createGroup = async (
  name: string,
  userId: string,
  groupId?: string,
  domain?: string,
  webhook?: DBWebhook,
  botToken?: string
): Promise<DatabaseResult<string>> => {
  // Check if name already exists - naive approch, may want to change if scaling
  const lowerCaseName = name.toLowerCase();
  // TODO: trim whitespace and compare that too //.replace(/\s\s+/g, " ")
  // TODO note that for slack workspaces their names can collide with a non-slack workspace
  // and you get no notification why. It just fails to create the grup and you see a spinner forever.
  const nameQuery = groupsCollection.where("name", "==", lowerCaseName);

  // TODO: tx?
  const res = await firestore.runTransaction(async (tx) => {
    const userDoc = await usersCollection.doc(userId).get();
    const userData = userDoc.data() as DBUser;
    const userDisplayName = userData.displayName;

    const results = await nameQuery.get();
    for (const doc of results.docs) {
      const data = doc.data() as DBGroup;
      if (data.name.toLowerCase() === lowerCaseName) {
        return {
          success: false,
          error: DatabaseError.AlreadyExists,
        } as DatabaseResult<string>;
      }
    }

    const dbGroup: DBGroup = {
      name: lowerCaseName,
      owners: [userId],
      users: { [userId]: userDisplayName },
      categories: { introductions: { videos: [] } },
      invite: name,
      domain: domain || "",
      botToken: botToken || "",
    };

    if (webhook) {
      dbGroup.categories.introductions.webhook = webhook
    }

    let ref;
    if (groupId) {
      await groupsCollection.doc(groupId).set(dbGroup);
      ref = groupsCollection.doc(groupId);
    } else {
      ref = await groupsCollection.add(dbGroup);
    }
    const res = await addGroupToUser(userId, ref.id);
    if (!res.success) {
      return res as DatabaseResult<string>;
    }

    const dbInvite: DBInvite = {
      groupId: ref.id,
      name: lowerCaseName,
      domain: domain || "",
    };
    const inviteRef = await invitesCollection.add(dbInvite);

    ref.update({ invite: inviteRef.id });
    return { success: true, data: ref.id } as DatabaseResult<string>;
  });
  return res;
};

export const getGroupNames = async (
  groupIds: string[]
): Promise<DatabaseResult<{ [key: string]: string }>> => {
  try {
    const data: { [key: string]: string } = {};
    for (const groupId of groupIds) {
      const groupDoc = await groupsCollection.doc(groupId).get();
      const groupData = groupDoc.data() as DBGroup;
      data[groupId] = groupData.name;
    }
    return { success: true, data };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const createUser = async (
  userId: string,
  displayName: string | null,
  email: string | null
): Promise<DatabaseResult<void>> => {
  try {
    const dbUser: DBUser = {
      displayName: displayName || userId,
      groups: {},
      email: email || "",
    };

    await usersCollection.doc(userId).set(dbUser);
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const addGroupToUser = async (
  userId: string,
  groupId: string
): Promise<DatabaseResult<void>> => {
  try {
    const userDoc = await usersCollection.doc(userId).get();
    const userData = userDoc.data() as DBUser;
    const newGroups = Object.keys(userData.groups).includes(groupId)
      ? userData.groups
      : {
          [groupId]: { intro: null, videos: [] },
          ...userData.groups,
        };
    usersCollection.doc(userId).update({ groups: newGroups });
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

const addUserToGroup = async (
  userId: string,
  groupId: string
): Promise<DatabaseResult<void>> => {
  try {
    const userDoc = await usersCollection.doc(userId).get();
    const userData = userDoc.data() as DBUser;
    const userDisplayName = userData.displayName;

    const groupDoc = await groupsCollection.doc(groupId).get();
    const groupData = groupDoc.data() as DBGroup;
    const newUsers = Object.keys(groupData.users).includes(userId)
      ? groupData.users
      : { [userId]: userDisplayName, ...groupData.users };
    groupsCollection.doc(groupId).update({ users: newUsers });
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const getInviteDetails = async (
  inviteId: string
): Promise<DatabaseResult<Partial<Group>>> => {
  try {
    const inviteDoc = await invitesCollection.doc(inviteId).get();
    const inviteData = inviteDoc.data() as DBInvite;
    return {
      success: true,
      data: {
        id: inviteData.groupId,
        name: inviteData.name,
        domain: inviteData.domain,
      },
    };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const fulfillInvite = async (
  userId: string,
  inviteId: string
): Promise<DatabaseResult<void>> => {
  const res = await firestore.runTransaction(async (tx) => {
    try {
      const inviteDetails = await getInviteDetails(inviteId);
      if (!inviteDetails.success) {
        return inviteDetails;
      }
      if (!inviteDetails || !inviteDetails.data || !inviteDetails.data.id) {
        return { success: false, error: DatabaseError.UnknownError };
      }
      const res1 = await addGroupToUser(userId, inviteDetails.data.id);
      if (!res1.success) {
        return res1;
      }
      const res2 = await addUserToGroup(userId, inviteDetails.data.id);
      if (!res2.success) {
        return res2;
      }
      return { success: true } as DatabaseResult<void>;
    } catch (e) {
      return { success: false, error: parseError(e) };
    }
  });
  return res;
};

export const updateUserInfo = async (
  username: string,
  userId: string,
  email?: string
): Promise<DatabaseResult<void>> => {
  const res = await firestore.runTransaction(async (tx) => {
    try {
      await getUser(userId).update({ displayName: username, email });
      return { success: true } as DatabaseResult<void>;
    } catch (e) {
      return { success: false, error: parseError(e) };
    }
  });
  return res;
};

export const joinSlackGroup = async (
  userId: string,
  groupId: string
): Promise<DatabaseResult<void>> => {
  const res = await firestore.runTransaction(async (tx) => {
    try {
      const doc = await groupsCollection.doc(groupId).get();
      // Check if user is already in group before adding
      if (doc.exists) {
        const groupInfo = doc.data() as DBGroup;
        if (groupInfo.users[userId]) {
          return { success: true } as DatabaseResult<void>;
        }
      }
      const res1 = await addGroupToUser(userId, groupId);
      if (!res1.success) {
        return res1;
      }
      return { success: true } as DatabaseResult<void>;
    } catch (e) {
      return { success: false, error: parseError(e) };
    }
  });
  return res;
};

export const logVisit = async (
  userId: string,
  groupId: string
): Promise<DatabaseResult<void>> => {
  try {
    usersCollection.doc(userId).update({ lastVisitedGroup: groupId });
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const setUsername = (
  userId: string,
  groupId: string,
  displayName: string
): DatabaseResult<void> => {
  try {
    groupsCollection.doc(groupId).update({ [`users.${userId}`]: displayName });
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};
