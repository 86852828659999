import React from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";

import FullscreenVideo from "./Video";
import Controllers from "./Controllers";
import Button from "./Button";

const Description = styled.div`
  text-align: left;

  h1 {
    font-size: 42px;
  }
  h2 {
    font-weight: 400;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 21px;
    }
    h2 {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const Nav = styled.div`
  position: fixed;
  -webkit-transform: translateZ(0);
  right: 40px;
  top: 20px;
  z-index: 4;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
`;

/* Not signed in landing page */
const NotSignedIn = ({
  title,
  blurb,
  cta,
}: {
  title: string;
  blurb: string;
  cta: string;
}): JSX.Element => {
  const { code } = useParams();

  return (
    <Container>
      <Nav>
        <Link to="/signup" className="sign-in-button">
          <Button inverted>SIGN IN</Button>
        </Link>
      </Nav>
      <FullscreenVideo
        src={
          process.env.REACT_APP_ENV === "production"
            ? "https://firebasestorage.googleapis.com/v0/b/async-hello-public-videos.appspot.com/o/LandingPage.mov?alt=media&token=e398123c-eb75-449a-90ae-25a59b34d259"
            : "https://firebasestorage.googleapis.com/v0/b/async-hello-staging.appspot.com/o/LandingPage.mov?alt=media&token=b3ea26b3-6362-402e-9fb9-02c5caa23467"
        }
      />
      <Controllers
        left={
          <Description className="description">
            <h1 className="overlayText">{title}</h1>
            <h2 className="overlayText">{blurb}</h2>
          </Description>
        }
        centre={
          <Link
            to={code ? `/signup/${code}` : "/signup"}
            className="cta-button"
          >
            <Button width="calc(max(100%, 120px))">{cta}</Button>
          </Link>
        }
      />
    </Container>
  );
};

export default NotSignedIn;
