export interface DBUser {
  displayName: string;
  groups: {
    [id: string]: { intro: string | null; videos: string[] };
  };
  lastVisitedGroup?: string;
  email?: string;
}

export interface DBWebhook {
  channel: string,
  channel_id: string,
  configuration_url: string,
  url: string
}

export interface DBCategory {
  videos: string[];
  webhook?: DBWebhook;
}

export interface DBGroup {
  name: string;
  owners: string[];
  users: { [id: string]: string };
  categories: { [key: string]: DBCategory };
  invite: string;
  welcomeId?: string;
  domain?: string;
  botToken?: string;
}

export interface DBVideo {
  userId: string;
  groupId: string;
  category: string;
  blurb: string;
  created: number;
  path: string;
  seenBy: string[];
  duration?: number
}

export interface DBInvite {
  groupId: string;
  name: string;
  domain?: string;
  expiry?: Date;
}

export enum DatabaseError {
  AlreadyExists = "Already Exists",
  InsufficientPermissions = "Insufficient Permissions",
  UnknownError = "Unknown Error",
}

export type DatabaseResult<T = void> =
  | {
      success: false;
      error: DatabaseError;
    }
  | (T extends void
      ? {
          success: true;
        }
      : {
          success: true;
          data: T;
        });
