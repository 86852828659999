import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import StyledModal from "./Modal";

const StyledErrorMessage = styled.div`
  color: red;
  text-align: center;
  top: 100px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    h2 {
      font-size: 18px;
    }
  }
`;

const Icon = styled.div`
  padding: 10px;
`;

interface Props {
  children?: React.ReactNode;
}

export const ErrorMessage = (props: Props): JSX.Element => (
  <StyledModal isOpen={true}>
    <StyledErrorMessage className="error-message">
      <Icon className="error-icon">
        <FontAwesomeIcon icon={faRocket} color="red" size="3x" />
      </Icon>
      <h2>{props.children}</h2>
    </StyledErrorMessage>
  </StyledModal>
);
