/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Webcam from "react-webcam";

import { TopProgressBar } from "../../components/ProgressBar";
import Controllers from "../../components/Controllers";
import { StopRecordingButton, StartRecordingButton } from "./RecordingButtons";

export const options = { mimeType: "video/webm;codecs=vp8,opus" };
const blobOptions = { type: "video/webm;codecs=vp8,opus"};
const maxVideoLength = 45; // 60s is too long, it gets a bit boring actually.

const WebcamRecordPage = ({
  setBlob,
  setDuration
}: {
  setBlob: (blob: any) => any;
  setDuration: (duration: number) => any;
}): JSX.Element => {
  const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = React.useState(false);
  const [seconds, setSeconds] = React.useState(0);

  const handleDataAvailable = React.useCallback(
    ({ data }: { data: any }) => {
      if (data.size > 0) {
        const newBlob = new Blob([data], blobOptions);
        setBlob(newBlob);
      }
    },
    [setBlob]
  );

  const handleStartCaptureClick = React.useCallback(() => {
    if (webcamRef && webcamRef.current) {
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream as MediaStream,
        options
      );
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef?.current?.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  React.useEffect(() => {
    capturing &&
      setTimeout(() => {
        if (seconds === maxVideoLength - 1) {
          handleStopCaptureClick();
        } else {
          setSeconds(seconds + 1);
          setDuration(seconds + 1)
        }
      }, 1000);
  }, [capturing, seconds]);
  return (
    <>
      {capturing && (
        // Add + 2 to make the progress bar look nicer
        <TopProgressBar value={seconds + 2} max={maxVideoLength + 2} />
      )}
      <Webcam
        audio={true}
        mirrored={true}
        ref={webcamRef}
        height={"100%"}
        width={"100%"}
        videoConstraints={{
          aspectRatio: window.innerWidth / window.innerHeight,
        }}
        className="webcam"
      />
      <Controllers
        centre={
          capturing ? (
            <StopRecordingButton onClick={handleStopCaptureClick} />
          ) : (
            <StartRecordingButton onClick={handleStartCaptureClick} />
          )
        }
      />
    </>
  );
};

export default WebcamRecordPage;
