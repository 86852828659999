import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextLogo from "../static/images/TextLogo";
import { DataContext } from "../state/DataContext";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  text-decoration: none;
  position: fixed;
  left: 0;
  margin: 30px;
  z-index: 1;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    transition: all 100ms ease;
  }
`;

const Logo = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}): JSX.Element => {
  const { group } = React.useContext(DataContext);
  const link = group ? `/${group.id}/introductions` : "/";

  return (
    <StyledLink to={link} className="logo">
      <TextLogo width={width} height={height} />
    </StyledLink>
  );
};

export default Logo;
