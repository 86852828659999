import React from "react";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faLink } from "@fortawesome/free-solid-svg-icons";
import StyledModal from "./Modal";
import styled from "styled-components";
import { detectMobile } from "../utils";

const CopyLink = styled.div`
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
  transition: color ease-in-out 150ms;
  cursor: pointer;
`;

const CopiedText = styled.div`
  padding: 0.2rem 0;
  min-height: 30px;
  color: ${(props) => props.theme.colors.muted};
`;

const InviteModal = ({ code }: { code: string }): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const inviteLink = `https://${window.location.host}/join/${code}`;
  const isMobile = detectMobile();

  return (
    <div>
      {isMobile ? (
        <Button round onClick={() => setIsOpen(true)} className="invite-button">
          <FontAwesomeIcon icon={faUserPlus} size="lg" />
        </Button>
      ) : (
        <Button
          width={"140px"}
          onClick={() => setIsOpen(true)}
          className="invite-button"
        >
          <FontAwesomeIcon icon={faUserPlus} /> INVITE
        </Button>
      )}
      <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <h2>Invite people to join your space</h2>
        <p>
          When somebody joins a space, the first thing they will see is your
          welcome video. Then they will be asked to record their own.
        </p>
        <CopyLink
          className="invite-copy"
          onClick={() => {
            navigator.clipboard.writeText(inviteLink);
            setCopied(true);
          }}
        >
          <FontAwesomeIcon icon={faLink} /> Copy invite link
        </CopyLink>
        <CopiedText className="invite-copied">
          {copied ? "The invite link has been copied to your clipboard" : " "}
        </CopiedText>
      </StyledModal>
    </div>
  );
};

export default InviteModal;
