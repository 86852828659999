import { DBUser, DBGroup, DatabaseResult, DBVideo, Video } from "../types";
import {
  usersCollection,
  groupsCollection,
  storageRef,
  videosCollection,
  firestore,
  parseError,
  projectId,
} from "./init";
import { notifyChannel } from "../utils";

export const addVideoToUser = async (
  userId: string,
  groupId: string,
  category: string,
  videoId: string
): Promise<DatabaseResult<void>> => {
  try {
    const userDoc = await usersCollection.doc(userId).get();
    const userData = userDoc.data() as DBUser;
    const oldGroup = userData.groups[groupId];
    if (category === "introductions") {
      const oldIntro = userData.groups[groupId].intro;
      if (oldIntro) {
        await deleteVideo(oldIntro);
      }
      usersCollection.doc(userId).update({
        [`groups.${groupId}.intro`]: videoId,
      });
    } else {
      const newVideos = [videoId, ...oldGroup.videos];
      usersCollection
        .doc(userId)
        .update({ [`groups.${groupId}.videos`]: newVideos });
    }
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const addVideoToGroup = async (
  groupId: string,
  category: string,
  videoId: string,
  isWelcome?: boolean
): Promise<DatabaseResult<void>> => {
  try {
    const groupDoc = await groupsCollection.doc(groupId).get();
    const groupData = groupDoc.data() as DBGroup;
    if (isWelcome) {
      groupsCollection.doc(groupId).update({ welcomeId: videoId });
    } else {
      const videos = [videoId, ...groupData.categories[category].videos];
      groupsCollection
        .doc(groupId)
        .update({ [`categories.${category}.videos`]: videos });
    }
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const createVideo = async (
  groupId: string,
  userId: string,
  categoryId: string,
  blurb: string,
  blob: Blob,
  isWelcomeVideo: boolean,
  setUploading: (num: number) => void,
  webhookUrl?: string,
  duration?: number
): Promise<DatabaseResult<Video>> => {
  setUploading(5);
  const created = Date.now();
  const category = isWelcomeVideo ? "welcome" : categoryId;
  const suffix = blob.type.split(";")[0].split("/")[1];
  let path =
    groupId + "/" + category + "/" + userId + "/" + created + "." + suffix;
  const ref = storageRef.child(path);
  const metadata = { 
    contentType: blob.type,
    customMetadata: {}
  };
  metadata.customMetadata = { duration }
  let videoId = path;
  setUploading(10);
  const res = await firestore.runTransaction(async (tx) => {
    try {
      setUploading(15);
      await ref.put(blob, metadata);
      setUploading(55);
      path = ref.fullPath;
      setUploading(80);

      const dbVideo: DBVideo = {
        userId,
        groupId,
        category: category,
        blurb,
        created,
        seenBy: [],
        path,
        duration
      };
      const videoRef = await videosCollection.add(dbVideo);
      videoId = videoRef.id;

      const userRes = await addVideoToUser(
        userId,
        groupId,
        category,
        videoRef.id
      );
      if (!userRes.success) {
        return userRes;
      }
      setUploading(90);

      const groupRes = await addVideoToGroup(
        groupId,
        category,
        videoRef.id,
        isWelcomeVideo
      );
      if (!groupRes.success) {
        return groupRes;
      }

      setUploading(99);

      // Attempt to trigger conversion
      const functionUrl = `https://us-central1-${projectId}.cloudfunctions.net/convert/js?videoId=${videoId}&filePath=${path}`;
      fetch(functionUrl);
      return { success: true };
    } catch (e) {
      return { success: false, error: e };
    }
  });
  if (res.success) {
    const video: Video = {
      id: videoId,
      userId,
      groupId,
      category,
      blurb,
      created: new Date(created),
      path,
      seenBy: [],
      seen: false,
      duration
    };
    setUploading(100);
    if (!isWelcomeVideo && webhookUrl) {
      notifyChannel(videoId, userId, groupId, category, webhookUrl, blurb);
    }
    return { success: true, data: video } as DatabaseResult<Video>;
  }
  setUploading(-1);
  return res as DatabaseResult<Video>;
};

export const getVideoUrl = async (
  path: string
): Promise<DatabaseResult<string>> => {
  try {
    const url = await storageRef.child(path).getDownloadURL();
    return { success: true, data: url };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Failed to get video url for " + path);
    return { success: false, error: parseError(e) };
  }
};

export const loadVideo = async (
  videoId: string
): Promise<DatabaseResult<Video>> => {
  try {
    const videoDoc = await videosCollection.doc(videoId).get();
    const videoData = videoDoc.data() as DBVideo;
    const url = await getVideoUrl(videoData.path);
    const video: Video = {
      id: videoId,
      userId: videoData.userId,
      groupId: videoData.groupId,
      category: videoData.category,
      blurb: videoData.blurb,
      created: new Date(videoData.created),
      path: videoData.path,
      url: url.success ? url.data : undefined,
      seenBy: videoData.seenBy,
      seen: false,
      duration: videoData.duration
    };
    return { success: true, data: video };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const deleteVideo = async (
  videoId: string
): Promise<DatabaseResult<void>> => {
  const res = await firestore.runTransaction(async (tx) => {
    try {
      const videoDoc = await videosCollection.doc(videoId).get();
      const videoData = videoDoc.data() as DBVideo;

      // Remove references from user and group
      const userDoc = await usersCollection.doc(videoData.userId).get();
      const userData = userDoc.data() as DBUser;
      if (videoData.category === "introductions") {
        usersCollection
          .doc(videoData.userId)
          .update({ [`groups.${videoData.groupId}.intro`]: null });
      } else {
        const newVideos = userData.groups[videoData.groupId].videos.filter(
          (id) => id !== videoId
        );
        usersCollection
          .doc(videoData.userId)
          .update({ [`groups.${videoData.groupId}.videos`]: newVideos });
      }

      const groupDoc = await groupsCollection.doc(videoData.groupId).get();
      const groupData = groupDoc.data() as DBGroup;
      if (videoData.category === "welcome") {
        groupsCollection.doc(videoData.groupId).update({ welcomeId: null });
      } else {
        const videos = groupData.categories[videoData.category].videos.filter(
          (id) => id !== videoId
        );
        groupsCollection
          .doc(videoData.groupId)
          .update({ [`categories.${videoData.category}.videos`]: videos });
      }
      // Delete the actual video file and then its DB object
      const videoRef = storageRef.child(videoData.path);
      await videoRef.delete();
      await videosCollection.doc(videoId).delete();
      return { success: true };
    } catch (e) {
      return { success: false, error: parseError(e) };
    }
  });
  return res as DatabaseResult;
};

export const logWatch = async (
  userId: string,
  videoId: string
): Promise<DatabaseResult<void>> => {
  try {
    const videoDoc = await videosCollection.doc(videoId).get();
    const videoData = videoDoc.data() as DBVideo;
    if (!videoData.seenBy.includes(userId)) {
      const newSeenBy = [userId, ...videoData.seenBy];
      videosCollection.doc(videoId).update({ seenBy: newSeenBy });
    }
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};

export const setBlurb = async (
  videoId: string,
  blurb: string
): Promise<DatabaseResult<void>> => {
  try {
    videosCollection.doc(videoId).update({ blurb });
    return { success: true };
  } catch (e) {
    return { success: false, error: parseError(e) };
  }
};
