export const notifyChannel = async (
  videoId: string,
  userId: string,
  groupId: string,
  category: string,
  webhookUrl: string,
  blurb?: string,
): Promise<void> => {
  const location = window.location.origin;
  const videoUrl = `${location}/${groupId}/${category}?firstVideoId=${videoId}`;
  const params = {
    text: `<@${userId}> just recorded a new intro video. <${videoUrl}|Watch now> :tada:\n _${blurb}_`,
  };
  fetch(webhookUrl, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export const getSlackRedirectUri = (): string =>
  `http${!window.location.host.includes("localhost:3000") ? "s" : ""}://${
    window.location.host
  }/slack-auth-redirect`;

export const getSlackState = (): string => "";
