import React from "react";
import { useHistory } from "react-router-dom";

import { DataContext } from "../state/DataContext";
import Page from "../components/Page";
import LoadingSpinner from "../components/LoadingSpinner";
import NotSignedIn from "../components/NotSignedIn";
import Footer from "../components/Footer";

const LandingPage: React.FC = () => {
  const history = useHistory();
  const { loadingUser, user, setCategory } = React.useContext(DataContext);

  // Make sure any group data has been cleared
  React.useEffect(() => {
    setCategory("", "");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to last visited group page for logged in users
  React.useEffect(() => {
    if (
      user &&
      user.lastVisitedGroup &&
      Object.keys(user.groups).includes(user.lastVisitedGroup)
    ) {
      history.push(`/${user.lastVisitedGroup}/introductions`);
    } else if (user) {
      history.push(`/spaces`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Page>
      {loadingUser || user ? (
        <LoadingSpinner />
      ) : (
        <NotSignedIn
          title="Asynchronous Video Introductions"
          blurb="Record your video introduction in your own time, instead of sitting
        through a scheduled Zoom meeting to learn who everyone is."
          cta="CREATE TEAM SPACE"
        />
      )}
      <Footer />
    </Page>
  );
};

export default LandingPage;
