import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import { signInWithToken } from "../auth";
import { useLocation, useHistory, Link } from "react-router-dom";
import { getSlackRedirectUri, getSlackState } from "../utils";
import {
  projectId,
  initializeGroupIfNew,
  joinSlackGroup,
  updateUserInfo,
} from "../db";
import { ErrorMessage } from "../components/ErrorMessage";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    transition: all 100ms ease;
  }
`;

const SlackAuthRedirect: React.FC = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const state = query.get("state");
  const [error, setError] = React.useState(query.get("error"));

  React.useEffect(() => {
    const authorize = async () => {
      if (
        code &&
        process.env.REACT_APP_SLACK_CLIENT_ID &&
        process.env.REACT_APP_SLACK_CLIENT_SECRET
      ) {
        try {
          const params = new URLSearchParams();
          params.append("code", code);
          params.append("client_id", process.env.REACT_APP_SLACK_CLIENT_ID);
          params.append(
            "client_secret",
            process.env.REACT_APP_SLACK_CLIENT_SECRET
          );
          params.append("redirect_uri", encodeURI(getSlackRedirectUri()));
          const response = await fetch(
            `https://slack.com/api/oauth.v2.access`,
            {
              method: "POST",
              body: params,
            }
          );
          const body = await response.json();
          const uid = body.authed_user.id;
          // We need this bot_token for a bunch of different requests. Basically everything not a webhook.
          const botToken = body.access_token;
          // We need the webhook to post automatically to that channel
          const webhook = body.incoming_webhook;
          /*
          Note an incoming webhook has a channel_id, channel (the # name), configuration_url and url
          Presently we just need the url and channel_id.
          */

          const functionUrl = `https://us-central1-${projectId}.cloudfunctions.net/token/js?uid=${uid}`;
          const functionResponse = await fetch(functionUrl);
          const functionBody = await functionResponse.json();
          await signInWithToken(functionBody.token);

          const identityParams = new URLSearchParams();
          identityParams.append("token", body.authed_user.access_token);
          const identityResponse = await fetch(
            `https://slack.com/api/users.identity`,
            {
              method: "POST",
              body: identityParams,
            }
          );
          const identityBody = await identityResponse.json();
          await initializeGroupIfNew(
            identityBody.team.id,
            identityBody.team.name,
            identityBody.team.domain,
            uid,
            identityBody.user.name,
            webhook,
            botToken
          );
          await updateUserInfo(
            identityBody.user.name,
            uid,
            identityBody.user.email
          );
          await joinSlackGroup(uid, identityBody.team.id);
          history.push(`/${body.team.id}/introductions`);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          history.push(`/`);
        }
      } else {
        console.log("Unable to log in with slack");
        setError("Unable to log in with slack");
      }
    };

    if (code) {
      authorize();
    }
  });
  return error ? (
    <ErrorMessage>
      <p>Error: {error}</p>
      <StyledLink to="/" className="link-home">
        Go back to landing page
      </StyledLink>
    </ErrorMessage>
  ) : (
    <LoadingSpinner />
  );
};

export default SlackAuthRedirect;
