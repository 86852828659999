import React from "react";
import styled from "styled-components";
import { List } from "./List";

const Container = styled.div`
  position: absolute;
  bottom: 60px;
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  z-index: 3;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    bottom: 20px;
  }
`;

const Centre = styled.div`
  z-index: 4;
  margin: 0 40px;
  min-width: 1px;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

const Left = styled.div`
  width: calc(min(400px, 50%));
  margin-left: 40px;

  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 5px;
    margin-left: 0;
  }
`;

const Right = styled(List)`
  width: calc(min(400px, 50%));
  text-align: right;
  align-items: flex-end;
  margin-right: 40px;
  min-width: 1px;

  @media (max-width: 600px) {
    width: 80%;
    margin-right: 0;
  }
`;

const Controllers = ({
  left,
  centre,
  right,
}: {
  left?: React.ReactNode;
  centre?: React.ReactNode;
  right?: React.ReactNode;
}): JSX.Element => (
  <Container className="controllers">
    <Left className="controllers-left">{left}</Left>
    <Centre className="controllers-centre">{centre}</Centre>
    <Right className="controllers-right">{right}</Right>
  </Container>
);

export default Controllers;
